import React from 'react';
import Layout from '../../components/Layout';
import SEO from '../../components/seo';
import { SubHeroColumns } from '../../components/shared/SubHero';

import { Heading, Container, SimpleGrid, Box, Text, VStack, Image, Flex, HStack } from '@chakra-ui/react';
import { ContactUsBanner, ServiceCard, SlidingHero, TestimonialCard } from '../../components/shared';

import SwiperCore, { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

import { useHomeCommercialPage } from '../../GraphQL/Queries';

SwiperCore.use([Pagination, Navigation]);

const CommercialHomePage = ({ location }) => {
  const data = useHomeCommercialPage();

  const { acf_hero, acf_text_block_title, acf_testimonials, acf_contact_cta } = data.pageBy;

  const settings = {
    pagination: {
      el: '.testimonial-commercial__swiper-pagination',
      type: 'bullets',
    },
  };
  return (
    <Layout location={location}>
      <SEO title="Commercial" />
      <SlidingHero images={acf_hero.sliderImages} heroHeadline="Construction services you can count on." />
      <SubHeroColumns
        heading={acf_text_block_title.title}
        body={acf_text_block_title.content}
        secondHeading={acf_text_block_title.secondTitle}
        secondBody={acf_text_block_title.secondContent}
      />
      <Container>
        <Box py="2rem" paddingRight={{ base: '2rem', md: 'unset' }}>
          <SimpleGrid minChildWidth="250px" spacing="40px">
            {data.services.nodes.map((service, i) => {
              const descr = service.excerpt.replace(/<\/?[^>]+(>|$)/g, '');
              return (
                <ServiceCard
                  key={i}
                  url={service.acf_service_info.serviceUrl}
                  title={service.title}
                  image={service.acf_service_info.background.sourceUrl}
                  icon={service.acf_service_info.icon.sourceUrl}
                  description={descr}
                  imagePosition="30% 50%"
                />
              );
            })}
          </SimpleGrid>
        </Box>
      </Container>

      <Box height="content-fit" mb="5rem">
        <Box maxHeight="487px" bg="primary" my="2rem" pl={{ base: '2rem', md: 'unset' }}>
          <Container display={{ md: 'flex' }} flexDirection={{ md: 'row-reverse' }} justifyContent={{ md: 'flex-end' }}>
            <VStack
              color="white"
              spacing="1rem"
              align="flex-start"
              pt={{ base: '2rem' }}
              mt={{ md: '4rem' }}
              maxW={{ md: '326px' }}
              ml={{ md: '4rem' }}
            >
              <Heading fontSize="35px">{acf_testimonials.mainTitle}</Heading>
              <Text textStyle="p" pr={{ base: '2rem', md: 'unset' }}>
                {acf_testimonials.mainContent}
              </Text>
            </VStack>
            <Image
              src={acf_testimonials.image.sourceUrl}
              alt={acf_testimonials.image.altText}
              objectFit="cover"
              objectPosition="right"
              mt="2rem"
              height={{ base: '244px', md: '573px' }}
              width={{ base: '100%', md: '565px' }}
              mx={{ md: '2rem' }}
            />
          </Container>
        </Box>
        <Container>
          {/* Desktop */}
          <Flex mt="-2rem" display={{ base: 'none', md: 'flex' }} justify="space-between">
            <Box width="488px">
              <TestimonialCard>
                <Box p="3.75rem 2rem">
                  <HStack align="flex-start" spacing="1rem">
                    <Image src="/icons/quote.svg" boxSize="40px" mt="-1rem" />
                    <VStack align="flex-start" spacing="1rem">
                      <Text textStyle="p">
                        We have had the pleasure of working with CH Global Construction on numerous projects. CH Global
                        Construction has the workmanship, integrity, and attention that exceeds our expectations every
                        time. We’re more than happy to continue working with this team.
                      </Text>
                      <Text textStyle="pBold" color="primary">
                        John T.
                      </Text>
                    </VStack>
                  </HStack>
                </Box>
              </TestimonialCard>
            </Box>
            <Box width="488px" mt="-3rem">
              <TestimonialCard>
                <Box p="3.75rem 2rem">
                  <HStack align="flex-start" spacing="1rem">
                    <Image src="/icons/quote.svg" boxSize="40px" mt="-1rem" />
                    <VStack align="flex-start" spacing="1rem">
                      <Text textStyle="p">
                        We are so happy with our new kitchen! Thank you so much for your hard work and advice. It was an
                        amazing experience to work with you!
                      </Text>
                      <Text textStyle="pBold" color="primary">
                        Rick S.
                      </Text>
                    </VStack>
                  </HStack>
                </Box>
              </TestimonialCard>
            </Box>
          </Flex>

          {/* Mobile */}
          <Box pr="2rem" mt="5rem" display={{ base: 'block', md: 'none' }}>
            <TestimonialCard clipped>
              <Swiper pagination={settings.pagination} autoplay>
                <SwiperSlide>
                  <Box p="2rem 2rem">
                    <VStack align="flex-start" spacing="1rem">
                      <Text textStyle="p" lineHeight="1.75">
                        We have had the pleasure of working with CH Global Construction on numerous projects. CH Global
                        Construction has the workmanship, integrity, and attention that exceeds our expectations every
                        time. We’re more than happy to continue working with this team.
                      </Text>
                      <Text textStyle="pBold" color="primary">
                        John T.
                      </Text>
                    </VStack>
                  </Box>
                </SwiperSlide>
                <SwiperSlide>
                  <Box p="2rem 2rem">
                    <VStack align="flex-start" spacing="1rem">
                      <Text textStyle="p" lineHeight="1.75">
                        We are so happy with our new kitchen! Thank you so much for your hard work and advice. It was an
                        amazing experience to work with you!
                      </Text>
                      <Text textStyle="pBold" color="primary">
                        Rick S.
                      </Text>
                    </VStack>
                  </Box>
                </SwiperSlide>
                <Box pl="2rem" pb="1rem" mt="-1.5rem" className="testimonial-commercial__swiper-pagination"></Box>
              </Swiper>
            </TestimonialCard>
          </Box>
        </Container>
      </Box>

      <ContactUsBanner
        heading={acf_contact_cta.contactCtaTitle}
        description={acf_contact_cta.contactCtaContent}
        image={acf_contact_cta.contactCtaBackgroundImage.sourceUrl}
        altText={acf_contact_cta.contactCtaBackgroundImage.altText}
        url={acf_contact_cta.contactCtaButton.url}
      />
    </Layout>
  );
};

export default CommercialHomePage;
